$('a[href^="http://"], a[href^="https://"]')
        .not('[href*="'+window.location.hostname+'"]')
        .attr('target','_blank');

$("a[href='"+location.href+"']").addClass('on');

cbpBGSlideshow.init();

$(window).on('scroll', function () {
	var shrinkOn = 50;
    var further = 50;
	var header = $("header");
	var fixed = $(".fixed");
    if (shrinkOn <= $(window).scrollTop()){
        // if so, add the small class
        header.addClass('small');
    } else {
        // otherwise remove it
        header.removeClass('small');
    }
    if (further <= $(window).scrollTop()){
        // if so, add the small class
        fixed.addClass('yes');
    } else {
        // otherwise remove it
        fixed.removeClass('yes');
    }
});


$('h2.expandable').click(function(){
    $(this).next().slideToggle(function(){
        $(this).prev().toggleClass('open');
    });
});

$('.magnific').magnificPopup({
    type:'image',
    gallery:{
        enabled:true,
        navigateByImgClick: false
        },
    callbacks:{
        imageLoadComplete: function() {
            var height = $('.mfp-figure > figure').height();
            $('.mfp-img').smoothZoom('destroy').smoothZoom({
                // zoom_MAX:300,
                height: height - 80,
                background_COLOR: 'transparent',
                border_SIZE: 0,
                pan_BUTTONS_SHOW: "NO",
                button_SIZE: 24,
                button_BG_TRANSPARENCY: 100,
                initial_ZOOM: '',
                zoom_OUT_TO_FIT: false,
            });
            $('.mfp-img').css('padding',0);
        },
        testamos: function() {
            $('.mfp-img').smoothZoom('destroy');
        }
    }
});

$("img.lazy").lazyload({effect : "fadeIn"});

$('select.linkto').change(function(){
    if ($( this ).val() != "") window.location.href = $( this ).val();
    else return false;
});

$('form.delete').submit(function() {
    var c = confirm("Are you sure you want to delete?");
    return c;
});